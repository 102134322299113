import { extendTheme } from "@chakra-ui/react";

export const sizes = {
  maxWidth: "1800px",
};

export const accordionStates = {
  onHover: {
    bg: "grey",
    cursor: "pointer",
  },
};

export const buttonStates = {
  onHover: {
    bg: "grey",
    transform: "scale(1.1)",
    cursor: "pointer",
    transition: "transform 150ms ease",
  },
  onHoverHighlightedText: {
    color: "grey",
    transform: "scale(1.05)",
    cursor: "pointer",
    transition: "transform 150ms ease",
  },
  onAcitve: {
    bg: "#dddfe2",
    transform: "scale(0.98)",
    borderColor: "#bec3c9",
  },
  onFocus: {
    boxShadow:
      "0 0 1px 2px rgba(88, 144, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)",
  },
};

export const colours = {
  bg: "#282c34", //"#1a202c",
  text: "#fff",
  altColour: "red.400",
  darkColour: "#282c34",
  button: "red.400",
  subTitle: "red.400",
  buttonText: "#fff",
  hightlight: "red.400",
  hightlightText: "#fff",
  grey: "grey",
};

const theme = extendTheme({
  styles: {
    global: {
      html: {
        // TODO - may need to add for larger
        scrollPaddingTop: ["10px", "10px", "90px"],
      },
      body: {
        margin: "0 auto",
        fontFamily:
          "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
        WebkitFontSmoothing: "antialiased",
        MozOsxFontSmoothing: "grayscale",
        backgroundColor: colours.bg,
        maxWidth: sizes.maxWidth,
      },
      // "a.chakra-link": {
      //   color: "#D85D87",
      // },
      h1: {
        fontSize: "35px",
      },
      h3: {
        fontSize: "18px",
      },
      "ul li": {
        textAlign: "left",
        // listStylePosition: "outside",
        // listStyleType: "square",
      },
    },
  },
});

export default theme;
